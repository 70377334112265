<template>
  <div class="seven">
    <div class="bagray bagrayns">
      <div class="cursor" style="margin-left: -10px; margin-right: -10px">
        <el-row :gutter="20" style="padding: 0 10px">
          <el-col
            :span="6"
            class="pad5"
            v-for="(item, index) in sevenList"
            :key="index"
          >
            <el-card class="hovershadow is-always-shadow">
              <div class="indpic">
                <div class="listimage">
                  <img :src="item.img" alt="" />
                </div>
                <div class="kcstat flex-between">
                  <span>
                    <i class="el-icon-video-camera-solid"></i>
                    {{ item.process }}
                  </span>
                </div>
              </div>
              <div style="padding: 14px">
                <span class="nametit">{{ item.title }}</span>
                <div class="bottom clearfix">
                  <p class="descone">
                    <span>{{ item.chapter }}</span>
                    <el-divider direction="vertical"></el-divider>
                    <span>{{ item.join }}</span>
                    <el-divider direction="vertical"></el-divider>
                    <span>讲师:{{ item.lecturer }}</span>
                  </p>
                  <div class="isfree flex-between">
                    <span>￥{{ item.money }}</span>
                    <el-button type="warning" size="mini">免费</el-button>
                  </div>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sevenList: [
        {
          img: require("@/assets/images/onlinecourses/ba.png"),
          process: "进行中",
          title: "统计问题",
          chapter: "一节",
          join: "4人加入",
          lecturer: "ddddd",
          money: 1,
        },
        {
          img: require("@/assets/images/onlinecourses/ba.png"),
          process: "已结束",
          title: "统计问题",
          chapter: "二节",
          join: "3人加入",
          lecturer: "ggggu",
          money: 1,
        },
        {
          img: require("@/assets/images/onlinecourses/ba.png"),
          process: "已结束",
          title: "统计问题",
          chapter: "一节",
          join: "2人加入",
          lecturer: "ddfbv",
          money: 2,
        },
        {
          img: require("@/assets/images/onlinecourses/ba.png"),
          process: "已结束",
          title: "统计问题",
          chapter: "一节",
          join: "4人加入",
          lecturer: "ddddd",
          money: 1,
        },
        {
          img: require("@/assets/images/onlinecourses/ba.png"),
          process: "已结束",
          title: "统计问题",
          chapter: "一节",
          join: "0人加入",
          lecturer: "ymdgh",
          money: 1,
        },
        {
          img: require("@/assets/images/onlinecourses/ba.png"),
          process: "已结束",
          title: "统计问题",
          chapter: "一节",
          join: "5人加入",
          lecturer: "hgmsf",
          money: 1,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.seven {
  .bagrayns {
    box-shadow: none;
  }
  .bagray {
    padding: 10px 20px 15px;
    background-color: #dcdfe6;
    border-radius: 5px;
    // box-shadow: 0 8px 10px -3px rgb(0 0 0 / 10%);
  }
  .cursor {
    cursor: pointer;
  }
  .pad5 {
    padding: 5px;
  }
  .hovershadow:hover {
    box-shadow: 0 0 5px 0 #c7c7c7, 0 1px 8px 0 #c7c7c7;
    transform: translateY(-3px);
  }
  .is-always-shadow {
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  }
  .indpic {
    position: relative;
    .listimage {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 55.24%;
    }
    .listimage img {
      width: 100%;
    }
    .kcstat {
      position: absolute;
      width: 100%;
      padding: 5px;
      bottom: 0;
      color: #fff;
      background: rgba(0, 0, 0, 0.2);
      font-size: 12px;
    }
    .flex-between {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-content: center;
    }
    .kcstat span {
      display: flex;
      align-items: center;
    }
    .kcstat i {
      font-size: 20px;
      color: red;
      margin-right: 5px;
    }
  }
  .nametit {
    display: inline-block;
    height: 25px;
    line-height: 25px;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
  }
  .nametit:hover {
    color: #3ce4bc;
  }
  .bottom p {
    font-size: 12px;
    color: #ccc;
  }
  .descone {
    height: 0.3rem;
    line-height: 0.2rem;
    overflow: hidden;
  }
  .isfree {
    margin-top: 10px;
  }
  .isfree span {
    color: red;
    font-size: 14px;
  }
  .flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }
}
.hovershadow ::v-deep.el-card__body {
  padding: 0px;
}
.isfree ::v-deep .el-button--warning {
  background: linear-gradient(#f47349 0%, #f0955d 70%);
}
</style>